import React, { useEffect } from 'react';
import { Spinner } from '../common/Spinner';
import live2D from '../../../img/Live2D.png';
import vts from '../../../img/vtslonglogo.png';

export function LoadingScreen() {

  // I make some shit for pre-cache the img
  const imageSteps = [
    'https://avatech-avatar-dev1.nyc3.cdn.digitaloceanspaces.com/public-avatars/welcome_gif/welcome1.gif',
    'https://avatech-avatar-dev1.nyc3.cdn.digitaloceanspaces.com/public-avatars/welcome_gif/welcome2.gif',
    'https://cdn.discordapp.com/attachments/750003831902830655/986491276474069012/unknown.png',
    'https://avatech-avatar-dev1.nyc3.cdn.digitaloceanspaces.com/public-avatars/welcome_gif/welcome4.png',
    'https://cdn.discordapp.com/attachments/750003831902830655/986491798618779688/unknown.png',
    'https://avatech-avatar-dev1.nyc3.cdn.digitaloceanspaces.com/public-avatars/welcome_gif/welcome5.png',
    'https://avatech-avatar-dev1.nyc3.cdn.digitaloceanspaces.com/public-avatars/welcome_gif/welcome_final.gif',
  ];

  useEffect(() => {
    imageSteps.forEach((image) => {
      const img = new Image();
      img.src = image;
    });
  }, []);

  return <PureLoadingScreen/>;
}

export function PureLoadingScreen(props: {label?: string}) {
  return (
    <div className='custom-bg w-screen h-screen text-center items-center flex justify-center gap-2'>
      {props.label ?? 'Setting up playground...'}
      <Spinner />
    </div>
  )
}

export function Live2DLogo() {
  return (
    <div className='absolute bottom-16 right-3 z-50 pointer-events-none'>
      <img src={live2D} width='200'></img>
    </div>
  );
}

export function VtsLogo() {
  return (
    <div className='absolute bottom-40 right-6 z-50 pointer-events-none'>
      <img src={vts} width='200'></img>
    </div>
  );
}
